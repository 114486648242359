<template>
	<div class="login-container">
		<div class="left">
			<img src="../../../static/login-bg.jpg" />
		</div>
		<div class="right">
			<div style="margin-bottom: 25px;display: flex;align-content: center;">
				<!-- <span class="fuhao"></span> -->
				<span class="loginT">云上品牌指导站(政务端)</span>
			</div>
			<el-form ref="ruleForm" :model="ruleForm" label-width="auto" label-position="top" :rules="rules" :hide-required-asterisk="true">
				<el-form-item label="用户名" prop="user">
					<el-input v-model="ruleForm.user" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="ruleForm.password" placeholder="请输入密码" type="password" show-password />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="loginBtn" :loading="loading" @click="onLogin('ruleForm')">
						登录
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {login} from "../../api/api";
	export default {
		name: 'home',
		data() {
			return {
				loading: false,
				ruleForm: {
					user: localStorage.getItem('account') || "",
					password: ''
				},
				rules: {
					user: [{ required: true, message: '请输入用户名', trigger: ['blur', 'change'] }],
					password: [{ required: true, message: '请输入密码', trigger: ['blur', 'change'] }],
					verificationCode: [{ required: true, message: '请输入验证码', trigger: ['blur', 'change'] }],
				}
			}
		},
		methods: {
			onLogin(formName) {
				if (!formName) return
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							const res = await login(this.ruleForm)						
							if (res.data.data.access) {
								localStorage.setItem('account', this.ruleForm.user)
								this.$store.commit('saveLogin', res.data.data.applicant)
								this.$router.push({
								  path:'/intellectualProperty',
								  query:{
								    company: res.data.data.applicant
								  }
								})
							} else {
								this.$message.error('账号或密码错误')
								this.loading = false;
							}
						} catch (e) {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		},
	}
</script>

<style scoped>
	.login-container {
		overflow: hidden;
		height: 100vh;
		width: 100vw;
		display: flex;
	}

	.login-container .left {
		flex: 1;
		position: relative;
		width: 65%;
		min-width: 65%;
		max-width: 65%;
		overflow: hidden;
	}

	.login-container .left>img {
		height: 100%;
		width: 100%;
		object-fit: fill;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		opacity: 0.8;
	}

	.login-container .left img::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
	}

	.login-container .right {
		background-color: #fff;
		padding: 0 70px;
		margin-top: -60px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
	}

	.login-container .right .fuhao {
		width: 4px;
		height: 20px;
		background: var(--el-color-primary);
		border-radius: 3px;
		display: inline-block;
		margin-right: 5px;
		margin-top: 5px;
	}

	.login-container .right .loginT {
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 20px;
		color: #000000;
		line-height: 29px;
		letter-spacing: 3px;
		text-align: center;
	}

	.login-container .right .codeImg {
		position: absolute;
		right: 2px;
		height: 30px;
		width: 120px;
	}

	.login-container .right .loginBtn {
		width: 100%;
		margin-top: 20px;
	}
</style>
